.LogoImage {
   height: 60px;

}
.searchIcon{
  font-size:40px !important;
  cursor: pointer;
}
.navCutome{
  color:var(--primary-color) !important;
}
.linkColor{
  color: var(--white) !important;
}
.barNav{
  color: #fff !important;
}
.linkColor:hover{
  color: var(--secondary2-color) !important;
}
.searchIcon{
  color:white;
}
.proColor{
  color:var(--primary-color);

}

.profileDropdown{
  color: var(--white) !important;
}

.profileDropdown:hover{
  color: var(--secondary2-color) !important;
}



.nestedDropDownDiv{
  display: flex;
  position: absolute;

  ul{
    padding: 10px 0;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    height: fit-content;
    list-style-type: none;
  }

}

.nestedDropDownLi{
  margin: 0 !important;
  padding: 3px 30px !important;
  position: relative;
}

.nestedDropDownArrow{
  position: absolute !important;
  padding: 6px 14px;
}
.nestedDropDownArrowDirEn{
  right: 0;
}
.nestedDropDownArrowDirAr{
  left: 0; 
}

.accordionItemMobile{
  background: transparent !important;
  outline: none !important;
  border: none !important;
  h2{
    button{
      background: transparent !important;
      box-shadow: none !important;
      padding: 0;

      p{
        margin: 0;
      }
    }
    button:not(.collapsed){
      color: var(--nav-color) !important;
    }
    button:focus{
      border-color: transparent !important;
    }
  }
  
}

.accordionBodyMobile{
  padding: 3px 10px !important;
}

.linkBlock{
  display: block;
}


// .categoriesMainDropdown{
//   position: relative;
// }

// .subDropDown{
//   position: absolute;
//   z-index: 2;
// }

.statesDropDownDiv{
  position: absolute;
  background: var(--box-color);
  padding: 10px;
  height: 315px;
  max-height: 375px;
  width: 330px;
  overflow-y: scroll;
  display: flex;
  justify-content: flex-start;
  border-radius: 12px;
  margin: 10px 0 !important;
}
.imageContainerSpan{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spanTitle{
  margin-top:8px;
  fontSize:8px;
  // fontWeight:"bold";
}
.mobilestateHeight{
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
}

.statesImg{
  display: block;
  width: 33% !important;
  text-align: center;
margin: 15px 0;
padding: 0 5px;
max-height: 50px;

img{
  border-radius: 50%;
  border: 2px solid var(--primary-color);
}
}

.navigationBarContainer {
  font-size: 18px;

  cursor: pointer;
  .navigationBarUnorderedList {
    display: flex;
    list-style-type: none;
    // gap: 32px;
    margin: auto;
    padding: 0;
    // a{
    //
    // height: 30px;
    // padding: 0px;
    // margin: 0px 14px;
    // &:focus  {
    //   border-bottom: 2px solid $darkGreen;

    // }
    li {
      color: var(--nav-color) !important;
      height: 30px;
      padding: 0px;
      margin: 0px 14px;
    }
    //
    .activePath {
      border-bottom: 2px solid var(--secondary2-color) !important;
    }
    // height: 26px;
    // padding: 0px;
    // margin: 0px 12px;

    // &:hover {
    //   border-bottom: 1.5px solid $darkGreen;
    // }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: sticky;
  // position: absolute;
  z-index: 999;
  background: #f1f1f1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 70px;
  background-color: transparent;
  top: 0px;
  margin-bottom: -70px;
}


.headerContainer2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: sticky;
  // position: absolute;
  z-index: 999;
  background: var(--primary-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 70px;
  // background-color: transparent;
  top: 0px;
  margin-bottom: -70px;
}











.headerContainerHome {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: sticky;
  position: absolute;
  background: transparent;
  z-index: 999;
  box-shadow: none;
}
.activeNav {
  background: rgba(0,0,0,.77);
  color: var(--white) !important;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: space-around;
  .languageIcon{
    color: var(--white) ;
  }
  .statesIcon{
    color: var(--white) ;
  }
  .navigationBarContainer {
    .navigationBarUnorderedList {
      // a{
      //  .activePath {
      //     border-bottom: 2px solid white !important;

      //   // }

      li {
        color: var(--white) !important;
      }
      .activePath {
        border-bottom: 2px solid var(--secondary2-color) !important;
      }
    }
  }
  .rightSubContainer {
    div {
      // i {
      //   color: #dc6027;
      // }
      .userIcon {
        color: var(--white);
      }
    }
  }

}
.rightSubContainer {
  display: flex;
  // gap: 28px;
  align-items: center;

  i {
    color: var(--white) ;
    cursor: pointer;
  }
}

.navBarSearchMainDiv {
  min-width: 50px;
  max-width: 50px;
}

.languageBtn {
  font-size: 15px;
  font-weight: bolder;
  background: transparent;
  border-radius: 9px; 
  color: var(--white) !important;
  padding: 5px !important;
  // border: none;
  border: 1px solid var(--white);
}

.statesBtn {
  font-size: 19px;
  font-weight: bolder;
  background: transparent;
  border-radius: 9px; 
  color: var(--white) !important;
  padding: 2px 7px !important;
  border: 1px solid var(--white);
  margin: 0 10px;
}

.navSearchDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 14px;

  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow:  rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 0px 8px;
    background-color: var(--white);
  }
  i {
    color: var(--white);

  }
}

.statesDropDownDivMobile{
display: none !important;
}

@media (max-width: 1024px) {
  .mainStateDiv{
    // position: relative;
    // height: 100vh;
    // width: 100%;
    // top: 0;
  }

  .statesDropDownDivMobile{
    position: fixed;
    background: var(--box-color);
    padding: 10px;
    width: 100%;
    overflow-y: scroll;
    justify-content: flex-start;
    border-radius: 12px;
    margin: 0 !important;
    display: flex !important;
    height: 100vh;
    top: 60px;
    z-index: 99999;
  }

  .linkColor{
    color: var(--nav-color) !important;
  }
  .navigationBarContainer {
    display: none;
  }
  .logoDivAr {
    padding: 12px;
  }
  .rightSubContainer {
    display: none;
  }
  .burgerMenu {
    display: block;
    font-size: 20px;
    z-index: 45;
    border: 1px solid var(--white);
    padding: 2px 7px;
    border-radius: 8px;

    i {
    color: var(--white);
    }
  }
  .headerContainer {
    display: flex;
    justify-content: space-between;
    // padding: 0px 27px;
    align-items: center !important;
    // position: fixed;
  }
  .navigationBarContainerMobile {
    display: flex;
  }
  .showNavigationBarMobile {
    background-color: var(--primary-color);
    height: calc(100vh - 60px);
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 70px;
    transition: all 0.3s ease-in;
    width: 40%;

    .showNavBarMainMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 17px;
  
      .navigationBarUnorderedList {
        list-style-type: none;

        li {
          margin: 0px 0px 16px 0px;
          color: var(--white);
        }
      }
    }
  }
  // .navBarSearchMainDiv {
  //   margin: 0px 16px;
  // }
  .rightSubContainerMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .navMobileMargin {
      margin: 16px 0px 16px 0px;
     
    }
  }

  .navSearchDiv {
    // display: flex;
    // justify-content: center;
  }
  .activeNav {
    display: flex;
    justify-content: space-between;
  }
  .searchMenuDivMobile {
    display: flex;
    // gap: 18px;
    align-items: center;
    /* flex-direction: row-reverse; */
    padding: 11px;
    position: absolute !important;
    right: 3px !important;
  }
  .searchMenuDivMobileAr {
    position: absolute;
    left: 3px;
    display: flex;
    align-items: center;
    padding: 11px;
  }
  .showNavigationBarMobile .showNavBarMainMobile {
    .navigationBarUnorderedList {
      margin: 0px;
    }
  }
  .languageBtn{
  
  margin-top: 10px;
  }
}
@media (min-width: 1026px) {
  .burgerMenu {
    display: none;
  }
  .showNavBarMainContainerMobile {
    display: none;
  }
  .searchMenuDivMobile {
    display: none;
  }
  .searchMenuDivMobileAr {
    display: none;
  }
}

@media (max-width: 480px) {
  .navSearchDiv {
    input {
      width: 164px;
    }
  }

  .showNavigationBarMobile {
    width: 70%;
  }
}
@media (max-width: 360px) {
  .navSearchDiv {
    input {
      width: 150px;
    }
  }
}


@media (min-width:500px) and (max-width:1024px){
  .imageContainerSpan{
    margin-bottom: 30px !important;
    img{
      width:120px !important;
      height:120px !important;
    }
  }
  .statesImg{
    margin-bottom: 100px !important;

  }
}
@media (max-width:500px){
  .imageContainerSpan{
    img{
      width:100px !important;
      height:100px !important;
    }
  }
  .statesImg{
    margin-bottom: 80px !important;

  }
}